import React from 'react';
import './footer.css'




function Footer() {

  /* Use Date to set the current year in the let year, so we do not need to change it every year. */
  const d = new Date();
  let year = d.getFullYear();

  return (
    <footer className="footer">
      <div className="footer-content">
       
        <div className='contacts'>
            <p>Tlf: +45 46 34 75 00 </p>   
        </div>

        <div className='privacy'>
        <p><a
              href='https://www.termsfeed.com/live/afc66a02-1416-43cf-8530-e97c5ddd9947'
              style={{ textDecoration: 'underline', color: 'inherit' }}
              target='_blank'
              rel='noopener noreferrer'>
              Fortrolighedspolitik
            </a>    </p> 
        </div>

        <div className='argo'>
            <p>&copy; Copyright ARGO - {year}</p>
        </div>

      </div>     
    </footer>
  )
}

export default Footer;


